import { Loader2 } from "lucide-react";
import { cn } from "~/libs/styling";

type Props = {
  className?: string;
  size?: number;
};

export const Loader = ({ className, size = 20 }: Props) => {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <Loader2 size={size} className="animate-spin" />
    </div>
  );
};
